<template>
   <div class="template-create-done">
      <div class="done-container">
         <div class="done">
          <i class="material-icons">check</i>
         </div>
         <div class="done-title">템플릿 {{$route.query.update ? '수정' : '등록'}} 신청을 완료하였습니다.</div>
         <div class="done-desc">신청한 템플릿은 파트너 센터에서 확인할 수 있으며<br>관리자 심사 후 게시됩니다.</div>
         <router-link to="/partner_center/my_template">
          <button class="button">내 템플릿 목록 바로가기</button>
         </router-link>
      </div>
   </div>
</template>
<script>
export default {
   name: 'TemplateCreateDone',
   components: {
     
   },
   mixins: [],
   props: {
     
   },
   data() {
     return {
       
     }
   },
   computed: {
     
   },
   watch: {
     
   },
   mounted() {
     
   },
   methods: {
     
   }
};
</script>
<style lang='stylus' scoped>
@import '~assets/css/lp_main'

.template-create-done
  padding 64px 0
  background-color $gray3

.done-container
  background-color white
  padding 40px
  text-align center
  width 548px
  margin 40px auto
  .done
    background-color $primary
    width 60px
    height 60px
    display flex
    align-items center
    justify-content center
    color white
    border-radius 8px
    margin 0 auto 24px auto
    i
      font-size 28px
  .done-title
    font-size 22px
    font-weight 700
    color $main
    margin-bottom 8px

  .done-desc
    font-size 16px
    color $sub2
    line-height 24px
    margin-bottom 40px
  .button
    width 100%
    height 56px
    font-size 16px
    font-weight 700
    color $sub

@media (max-width: 600px)
  .template-create-done
    padding 60px 16px
    .done-container
      margin 16px 0
      width 100%
      padding 40px 16px
      .done-title
        font-size 18px
      .done-desc
        font-size 14px
        line-height 22px
</style>